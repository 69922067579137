import {useStore} from "vuex";
// import store from "@/store/index"
// import Roles from "@/components/common/constants/Roles";


export default function () {

    const store = useStore();



    /**
     * @param {Array} value
     * @returns {Boolean}
     * @example see @/views/permission/directive.vue
     */
    const checkPermission = (value) => {
        if (value && value instanceof Array && value.length > 0) {
            const roles = store.getters && store.getters["auth/loggedUser"].roles;
            const permissionRoles = value;
            const isRolesDefined = null !== roles && 'undefined' !== typeof (roles);
            return isRolesDefined && roles.some(role => {
                return permissionRoles.includes(role)
            });

        } else {
            console.error(`need roles! Like v-permission="['admin','editor']"`)
            return false
        }
    }


    return {checkPermission};
}