<template>
  <Dialog v-model:visible="show" :style="{width: '450px'}" :header="t('action.confirm')" :modal="true">
    <div class="confirmation-content">
      <i class="pi pi-exclamation-triangle p-mr-3" style="font-size: 2rem"/>
      <span v-if="item">{{ t('message.delete-confirm') }}</span>
    </div>
    <template #footer>
      <Button :label="t('label.no')" icon="pi pi-times" class="p-button-text" @click="hideAction"/>
      <Button :label="t('label.yes')" icon="pi pi-check" class="p-button-text" @click="deleteItemAction"/>
    </template>
  </Dialog>
</template>

<script setup>

import {onMounted, onUnmounted, ref} from 'vue';
import eventService from "@/services/EventService";
import {SHOW_SOGGETTO_DELETE_DIALOG} from "@/components/common/constants/Events";
import {useStore} from "vuex";
import useMessage from "@/composable/useMessage";
import {useI18n} from "vue-i18n";

const {t} = useI18n();
const store = useStore();
const {successWithKey} = useMessage();

let item = ref({});
let show = ref(false);

onMounted(() => {
  eventService.on(SHOW_SOGGETTO_DELETE_DIALOG, onShow);
});

onUnmounted(() => {
  eventService.off(SHOW_SOGGETTO_DELETE_DIALOG, onShow);
})

const onShow = (currentItem) => {
  show.value = true;
  item.value = currentItem
}

const deleteItemAction = () => {
  store.dispatch("soggetto/delete", item.value);
  successWithKey('message.delete-success');
  hideAction();
}

const hideAction = () => {
  show.value = false;
}

</script>

<style scoped>

</style>
