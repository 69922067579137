<template>
  <div class="grid">
    <div class="col-12">
      <div class="card">

        <!-- toolbar -->
        <Toolbar class="mb-4" v-permission="[Roles.BROKER, Roles.BUYER, Roles.SELLER, Roles.EXECUTION_MANAGER, Roles.SPEDIZIONIERE]">
          <template #start>
            <Button :label="t('action.invita-soggetto')" icon="pi pi-plus" class="p-button-success mr-2" @click="showNewDialog"/>
          </template>
        </Toolbar>

        <!-- datatable -->
        <PaginatedDataTable v-model:model-value="items" :title="t('label.soggetto', 2)">

          <template #columns>

            <!-- nomeBreve -->
            <Column field="nomeBreve" :header="t('label.soggetto.nome')" :sortable="true"/>

            <!-- vat number -->
            <Column field="eori" :header="t('label.soggetto.piva')" :sortable="true"/>

            <!-- gruppo eori -->
            <Column field="gruppoEori" :header="t('label.soggetto.gruppo-eori')"
                    :sortable="true" v-if="checkPermission([Roles.ADMIN])"/>

            <!-- broker -->
            <Column :header="t('label.broker')" :sortable="false" class="text-center"
                    v-if="checkPermission([Roles.ADMIN, Roles.BROKER])">
              <template #body="slotProps">
                <BooleanIndicator :status="slotProps.data.broker" :show-when-false="false"
                                  :true-tooltip="t('label.broker')"/>
              </template>
            </Column>

            <!-- buyer -->
            <Column :header="t('label.buyer')" :sortable="false" class="p-text-center"
                    v-if="checkPermission([Roles.ADMIN, Roles.BROKER])">
              <template #body="slotProps">
                <BooleanIndicator :status="slotProps.data.buyer" :show-when-false="false"
                                  :true-tooltip="t('label.buyer')"/>
              </template>
            </Column>

            <!-- seller -->
            <Column :header="t('label.seller')" :sortable="false" class="p-text-center"
                    v-if="checkPermission([Roles.ADMIN, Roles.BROKER])">
              <template #body="slotProps">
                <BooleanIndicator :status="slotProps.data.seller" :show-when-false="false"
                                  :true-tooltip="t('label.seller')"/>
              </template>
            </Column>

            <!-- spedizioniere -->
            <Column :header="t('label.spedizioniere')" :sortable="false" class="p-text-center"
                    v-if="checkPermission([Roles.ADMIN, Roles.EXECUTION_MANAGER])">
              <template #body="slotProps">
                <BooleanIndicator :status="slotProps.data.spedizioniere" :show-when-false="false"
                                  :true-tooltip="t('label.spedizioniere')"/>
              </template>
            </Column>

            <!-- loader -->
            <Column :header="t('label.caricatore')" :sortable="false" class="p-text-center"
                    v-if="checkPermission([Roles.ADMIN, Roles.EXECUTION_MANAGER])">
              <template #body="slotProps">
                <BooleanIndicator :status="slotProps.data.caricatore" :show-when-false="false"
                                  :true-tooltip="t('label.caricatore')"/>
              </template>
            </Column>

            <!-- unloader -->
            <Column :header="t('label.scaricatore')" :sortable="false" class="p-text-center"
                    v-if="checkPermission([Roles.ADMIN, Roles.EXECUTION_MANAGER])">
              <template #body="slotProps">
                <BooleanIndicator :status="slotProps.data.scaricatore" :show-when-false="false"
                                  :true-tooltip="t('label.scaricatore')"/>
              </template>
            </Column>

            <!-- surveyor -->
            <Column :header="t('label.verificatore')" :sortable="false" class="p-text-center"
                    v-if="checkPermission([Roles.ADMIN, Roles.BUYER, Roles.SELLER, Roles.EXECUTION_MANAGER])">
              <template #body="slotProps">
                <BooleanIndicator :status="slotProps.data.verificatore" :show-when-false="false"
                                  :true-tooltip="t('label.verificatore')"/>
              </template>
            </Column>

            <!-- custom_broker -->
            <Column :header="t('label.custom_broker')" :sortable="false" class="p-text-center"
                    v-if="checkPermission([Roles.ADMIN, Roles.BUYER, Roles.SELLER, Roles.EXECUTION_MANAGER])">
              <template #body="slotProps">
                <BooleanIndicator :status="slotProps.data.customBroker" :show-when-false="false"
                                  :true-tooltip="t('label.custom_broker')"/>
              </template>
            </Column>

            <!-- railway_company -->
            <Column :header="t('label.impresa_ferroviaria')" :sortable="false" class="p-text-center"
                    v-if="checkPermission([Roles.SPEDIZIONIERE])">
              <template #body="slotProps">
                <BooleanIndicator :status="slotProps.data.impresaFerroviaria" :show-when-false="false"
                                  :true-tooltip="t('label.impresa_ferroviaria')"/>
              </template>
            </Column>

            <!-- datains -->
            <Column field="datains" :header="t('label.dataInserimento')" :sortable="true">
              <template #body="slotProps">
                {{dateTimeFormat(slotProps.data.datains)}}
              </template>
            </Column>

            <!-- actions -->
            <!--<Column class="text-right">
              <template #body="slotProps">

                <Button icon="pi pi-trash" class="p-button-danger mr-2"
                        @click="showConfirmDeleteDialog(slotProps.data)"
                        v-if="checkPermission([Roles.ADMIN])"/>

              </template>
            </Column>-->

          </template>
        </PaginatedDataTable>

        <!-- dialogs -->
        <SoggettoRichiestaDataDialog/>
        <SoggettoDeleteDialog/>
      </div>

      <!-- company requests -->
      <div class="p-mt-4" v-if="!checkPermission([Roles.ADMIN])">
        <SoggettoRichiesteTable/>
      </div>

    </div>
  </div>
</template>

<script setup>
import {computed, onMounted} from "vue";
import SoggettoDeleteDialog from "@/components/anagrafica/soggetto/SoggettoDeleteDialog";
import EventService from "@/services/EventService";
import Roles from "@/components/common/constants/Roles";
import BooleanIndicator from "@/components/common/BooleanIndicator";
import {useStore} from "vuex";
import useAcl from "@/composable/useAcl";
import PaginatedDataTable from "@/components/common/datatable/PaginatedDataTable";
import {useI18n} from "vue-i18n";
import SoggettoRichiesteTable from "@/components/anagrafica/soggetto/richieste/SoggettoRichiesteTable";
import SoggettoRichiestaDataDialog from "@/components/anagrafica/soggetto/richieste/SoggettoRichiestaDataDialog";
import {
  SHOW_SOGGETTO_RICHIESTA_DATA_DIALOG
} from "@/components/common/constants/Events";
import useDateTime from "@/composable/useDateTime";

const store = useStore();
const {t} = useI18n();
const {checkPermission} = useAcl();
const {dateTimeFormat} = useDateTime();

const items = computed(() => store.getters["soggetto/items"]);

onMounted(() => {
  store.dispatch("soggetto/loadAll")
});


function showNewDialog() {
  EventService.emit(SHOW_SOGGETTO_RICHIESTA_DATA_DIALOG, {ruolo: ''});
}


/*function showConfirmDeleteDialog(it) {
  EventService.emit(SHOW_SOGGETTO_DELETE_DIALOG, it);
}*/

</script>

<style scoped>


</style>
